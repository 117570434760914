import {
  Box,
  Button,
  chakra,
  Heading,
  ListItem,
  OrderedList,
  Stack,
  UnorderedList,
} from "@chakra-ui/react"
import React, {
  ComponentType,
  createElement,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react"
import rehypeReact from "rehype-react"
import remarkFrontmatter from "remark-frontmatter"
import remarkGfm from "remark-gfm"
import remarkParse from "remark-parse"
import remarkRehype from "remark-rehype"
import remarkWikiLink from "remark-wiki-link"
import { unified } from "unified"
import { Textarea } from "./Textarea"
import { WikiLink } from "./WikiLink"

type Part = {
  text: string
  reactNode: ReactNode
  start: number
  end: number
  isYaml: boolean
}

type Content = {
  text: string
  parts: Array<Part>
}

const components: Partial<{
  [TagName in keyof JSX.IntrinsicElements]:
    | keyof JSX.IntrinsicElements
    | ComponentType<JSX.IntrinsicElements[TagName]>
}> = {
  ul: UnorderedList,
  ol: OrderedList,
  li: ListItem,
  pre: (props) => <chakra.pre {...props} whiteSpace="pre-wrap" />,
  p: (props) => <chakra.p {...props} whiteSpace="pre-wrap" />,
  h1: (props) => <Heading as="h1" {...props} size="2xl" />,
  h2: (props) => <Heading as="h2" {...props} size="xl" />,
  h3: (props) => <Heading as="h3" {...props} size="lg" />,
  h4: (props) => <Heading as="h4" {...props} size="md" />,
  h5: (props) => <Heading as="h5" {...props} size="sm" />,
  h6: (props) => <Heading as="h5" {...props} size="sm" />,
  a: (props) =>
    props.className?.match(/\binternal\b/) ? (
      <WikiLink name={props.href as string}>{props.children}</WikiLink>
    ) : (
      <chakra.a {...props} textDecor="underline" target="_blank" />
    ),
  blockquote: (props) => (
    <chakra.blockquote
      {...props}
      borderColor="gray.300"
      borderLeftWidth="3px"
      pl={2}
    />
  ),
}

type Props = {
  noteId: string | undefined
  text: string
  onSave: (newText: string) => void
}

export const CellEditor: FC<Props> = ({ noteId, text, onSave }) => {
  const [content, setContent] = useState<Content>({ text: "", parts: [] })
  const [editingCellIndex, setEditingCellIndex] = useState<number | undefined>()
  const [cellDraft, setCellDraft] = useState("")
  const [newCellDraft, setNewCellDraft] = useState("")

  const callAfterNextRender = useRef<Array<() => void>>([])
  const cellTextareaRef = useRef<HTMLTextAreaElement | null>(null)

  const internalStates = useRef({ pendingBodyUpdates: [] as string[] }).current

  useEffect(() => {
    const i = internalStates.pendingBodyUpdates.lastIndexOf(text)
    if (i !== -1) {
      internalStates.pendingBodyUpdates.splice(0, i + 1)
      return
    }

    setEditingCellIndex(undefined)
    setCellDraft("")

    setContent((content) => {
      if (content.text === text) return content
      return makeContent(text)
    })
  }, [noteId, text, internalStates])

  useLayoutEffect(() => {
    if (callAfterNextRender.current.length > 0) {
      for (const f of callAfterNextRender.current) {
        f()
      }
      callAfterNextRender.current = []
    }
  })

  const composeTextFromDraft = useCallback(
    (newCellText: string): string => {
      if (content === undefined) return ""
      if (editingCellIndex === undefined) return content.text

      const { text, parts } = content
      const part = parts[editingCellIndex]
      const before = text.slice(0, part.start)
      const after = text.slice(part.end)
      const appendix = newCellDraft === "" ? "" : `\n\n${newCellDraft}`
      const newText = `${before}${newCellText}${after}${appendix}`
      return newText
    },
    [content, editingCellIndex, newCellDraft]
  )

  const composeTextFromNewDraft = useCallback(
    (newText: string): string => {
      if (content === undefined) return ""
      if (newText === "") return content.text

      return `${content.text}\n\n${newText}`
    },
    [content]
  )

  const onClickCell = useCallback(
    (i: number) => {
      if (i === editingCellIndex || content === undefined) return

      if (newCellDraft !== "") {
        const newText = composeTextFromNewDraft(newCellDraft)
        onSave(newText)
        internalStates.pendingBodyUpdates.push(newText)
        setNewCellDraft("")
        setContent(makeContent(newText))
      }

      setEditingCellIndex(i)
      const cellText = content.parts[i].text
      setCellDraft(cellText)
      callAfterNextRender.current.push(() => {
        const e = cellTextareaRef.current
        if (e === null) return
        e.focus()
        const n = cellText.length
        e.setSelectionRange(n, n)
      })
    },
    [
      composeTextFromNewDraft,
      content,
      editingCellIndex,
      internalStates,
      newCellDraft,
      onSave,
    ]
  )

  const onChangeCellDraft = useCallback(
    (newCellText: string) => {
      if (noteId === undefined) return

      setCellDraft(newCellText)
      const newText = composeTextFromDraft(newCellText)
      onSave(newText)
      internalStates.pendingBodyUpdates.push(newText)
    },
    [composeTextFromDraft, internalStates, noteId, onSave]
  )

  const onBlurCell = useCallback(() => {
    const newText = composeTextFromDraft(cellDraft)
    const newContent = makeContent(newText)
    setContent(newContent)
    setEditingCellIndex(undefined)
    setCellDraft("")
  }, [cellDraft, composeTextFromDraft, setContent])

  const onClickAdd = useCallback(() => {
    const newText = `${composeTextFromDraft(cellDraft)}\n\n${newCellDraft}`

    onSave(newText)
    internalStates.pendingBodyUpdates.push(newText)

    const newContent = makeContent(newText)
    setContent(newContent)
    setEditingCellIndex(undefined)
    setCellDraft("")
    setNewCellDraft("")
  }, [cellDraft, composeTextFromDraft, internalStates, newCellDraft, onSave])

  const onChangeNewCellDraft = useCallback(
    (newDraftText: string) => {
      setNewCellDraft(newDraftText)

      const newText = composeTextFromNewDraft(newDraftText)
      onSave(newText)
      internalStates.pendingBodyUpdates.push(newText)
    },
    [composeTextFromNewDraft, internalStates, onSave]
  )

  return (
    <Stack overflowY="scroll" pb={16} wordBreak="break-all">
      {content.parts.map(({ reactNode, isYaml, text }, i) => (
        <Box key={i}>
          {i === editingCellIndex ? (
            <Textarea
              ref={cellTextareaRef}
              autosize
              value={cellDraft}
              onChange={onChangeCellDraft}
              onBlur={onBlurCell}
              w="100%"
              px={4}
              py={2}
              outline="none"
              resize="none"
              bg="orange.50"
              onSubmit={onBlurCell}
              _dark={{ bg: "purple.900" }}
            />
          ) : (
            <Box
              px={4}
              py={2}
              _hover={{ bg: "gray.100", _dark: { bg: "gray.700" } }}
              onClick={onClickCell.bind(null, i)}
              whiteSpace={isYaml ? "pre-wrap" : undefined}
            >
              {isYaml ? text : reactNode}
            </Box>
          )}
        </Box>
      ))}
      <Stack>
        <Textarea
          autosize
          value={newCellDraft}
          onChange={onChangeNewCellDraft}
          w="100%"
          px={4}
          py={2}
          mt={6}
          outline="none"
          resize="none"
          bg="pink.50"
          minRows={3}
          onSubmit={onClickAdd}
          _dark={{ bg: "teal.900" }}
        />
        <Box px={2}>
          <Button onClick={onClickAdd} w="100%">
            Add
          </Button>
        </Box>
      </Stack>
    </Stack>
  )
}

function makeContent(text: string): Content {
  const tree = unified()
    .use(remarkParse)
    .use(remarkGfm)
    .use(remarkFrontmatter)
    .use(remarkWikiLink, {
      permalinks: [],
      pageResolver: (name: string) => [name],
      hrefTemplate: (permalink: string) => permalink,
    })
    .parse(text)

  const processor = unified()
    .use(remarkGfm)
    .use(remarkRehype)
    .use(rehypeReact, {
      createElement,
      components,
    })

  const parts = tree.children.map((node): Part => {
    const start = node.position?.start.offset
    const end = node.position?.end.offset
    if (start === undefined || end === undefined) {
      throw new Error(`No offset! ${node}`)
    }

    return {
      text: text.slice(start, end),
      reactNode: processor.stringify(
        processor.runSync({ type: "root", children: [node] }, text),
        text
      ),
      start,
      end,
      isYaml: node.type === "yaml",
    }
  })

  return { text, parts }
}
