import { Link } from "@chakra-ui/react"
import React, { FC, PropsWithChildren, useMemo } from "react"
import { Link as ReactRouterLink } from "react-router-dom"
import { useNotes } from "../logic/notes"

export const WikiLink: FC<PropsWithChildren<{ name: string }>> = ({
  name,
  children,
}) => {
  const notes = useNotes({ type: "all" })
  const noteId = useMemo(
    () => notes?.find((n) => n.title === name)?.id,
    [notes, name]
  )

  return (
    <Link
      as={ReactRouterLink}
      to={noteId ? `/editor/${noteId}` : `/editor?name=${name}`}
      color="blue.500"
    >
      {children}
    </Link>
  )
}
