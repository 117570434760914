const chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

export function generateId(size = 22): string {
  const arraySize = Math.ceil(size / 2)

  const id = Array.from(crypto.getRandomValues(new Uint8Array(arraySize)))
    .map((n) => chars[n % 62] + chars[Math.floor(n / 62) % 62])
    .join("")

  return size % 2 === 1 ? id.slice(0, size) : id
}
