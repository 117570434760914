import { Box, Button, Center, Heading, Image, Text } from "@chakra-ui/react"
import React, { FC, useState } from "react"
import screenshot from "../assets/screenshot.png"
import { signIn } from "../logic/auth"
import { CellEditor } from "./CellEditor"

const demoText = `
# This is Wikiwi's editor

## heading 2

### heading 3

This is **editable preview**. You can edit text by **clicking** it.

- fruit
    - orange
    - grape
- fish
- meat
    - beef
    - chicken
    - pork
        - ham
        - bacon

↓↓ You can append text to bottom of document, by typing below and clicking "Add" button. (or Ctrl+Enter or Command+Enter)
`

export const About: FC = () => {
  const [text, setText] = useState(demoText)

  return (
    <Box maxWidth="600px" mx="auto" p={2}>
      <Heading as="h1" size="2xl" my={4}>
        Wikiwi
      </Heading>
      <Text>
        Wikiwi is personal note-taking app / wiki system with Markdown support.
        Currently it&apos;s under development.
      </Text>

      <Center my={4}>
        <Button colorScheme="red" onClick={signIn}>
          Try it: Sign in with Google
        </Button>
      </Center>

      <Heading as="h2" size="xl" mt={8} mb={4}>
        Screenshot
      </Heading>

      <Image src={screenshot} boxShadow="lg" my={4} />

      <Heading as="h2" size="xl" mt={8} mb={4}>
        Editor Demo
      </Heading>

      <Box
        boxShadow="xl"
        border="3px solid"
        borderColor="gray.300"
        rounded="xl"
        overflow="hidden"
      >
        <CellEditor noteId="dummy" text={text} onSave={setText} />
      </Box>

      <Text textAlign="center" my={8}>
        (C) 2023 Kaido Iwamoto
      </Text>
    </Box>
  )
}
