import { initializeApp } from "firebase/app"
import React from "react"
import { createRoot } from "react-dom/client"
import { App } from "./components/App"

const firebaseConfig = {
  apiKey: "AIzaSyCk0RvHz8Az18__0fji7HAeg6BkxpT7Azc",
  authDomain: "wikiwi-dd382.firebaseapp.com",
  projectId: "wikiwi",
  storageBucket: "wikiwi.appspot.com",
  messagingSenderId: "611990034031",
  appId: "1:611990034031:web:dd6cc88026d9dd4e865f9a",
  measurementId: "G-833GLBTNEK",
}
initializeApp(firebaseConfig)

createRoot(document.getElementById("app") as Element).render(<App />)
