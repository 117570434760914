import {
  addDoc,
  collection,
  FirestoreDataConverter,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore"
import { useCallback, useEffect, useState } from "react"
import { useUser } from "./auth"

export type Category = { id: string; name: string }

const converter: FirestoreDataConverter<Category> = {
  toFirestore({ id: _, ...rest }) {
    return rest
  },
  fromFirestore(snapshot, options) {
    const id = snapshot.id
    const { name } = snapshot.data(options)
    return {
      id,
      name: String(name),
    }
  },
}

export function useCategories(): [
  Category[],
  {
    createCategory: (name: string) => Promise<void>
    updateCategory: (category: Category) => Promise<void>
  }
] {
  const user = useUser()
  const [categories, setCategories] = useState<Category[]>([])

  useEffect(() => {
    if (user === undefined) return

    return onSnapshot(
      query(
        collection(
          getFirestore(),
          `users/${user.uid}/categories`
        ).withConverter(converter),
        orderBy("name", "asc")
      ),
      (snapshot) => {
        setCategories(snapshot.docs.map((d) => d.data()))
      }
    )
  }, [user])

  const createCategory = useCallback(
    async (name: string) => {
      if (user === undefined) return

      await addDoc(
        collection(
          getFirestore(),
          `users/${user.uid}/categories`
        ).withConverter(converter),
        { id: "", name }
      )
    },
    [user]
  )

  const updateCategory = useCallback(async (category: Category) => {
    //
  }, [])

  return [categories, { createCategory, updateCategory }]
}
