import {
  Box,
  Button,
  Checkbox,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from "@chakra-ui/react"
import React, { FC, useCallback, useState } from "react"
import { useCategories } from "../logic/categories"
import { useStateWithInitial } from "../utils/useStateWithInitial"

export const CategorySelectModal: FC<{
  isOpen: boolean
  onClose: () => void
  categoryIds: string[]
  onSelect: (categoryIds: string[]) => void
}> = ({ isOpen, onClose, categoryIds, onSelect }) => {
  const [categories, { createCategory }] = useCategories()
  const [nameDraft, setNameDraft] = useState("")
  const [selectedCategoryIds, setSelectedCategoryIds] = useStateWithInitial<
    string[]
  >(categoryIds, [categoryIds])

  const onClickAdd = useCallback(() => {
    const name = nameDraft.trim()
    if (name === "") return

    createCategory(name)
    setNameDraft("")
  }, [createCategory, nameDraft])

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Category</ModalHeader>
        <ModalBody>
          <Stack>
            {categories.map(({ id, name }) => (
              <HStack key={id}>
                <Checkbox
                  isChecked={selectedCategoryIds.includes(id)}
                  onChange={(e) =>
                    e.target.checked
                      ? setSelectedCategoryIds((ids) => ids.concat(id))
                      : setSelectedCategoryIds((ids) =>
                          ids.filter((id_) => id_ !== id)
                        )
                  }
                />
                <Box>{name}</Box>
              </HStack>
            ))}
            <HStack>
              <Input
                value={nameDraft}
                onChange={(e) => setNameDraft(e.target.value)}
                placeholder="category name"
              />
              <Button onClick={onClickAdd}>Add</Button>
            </HStack>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="blue"
            onClick={() => onSelect(selectedCategoryIds)}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
