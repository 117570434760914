import {
  collection,
  doc,
  DocumentData,
  FirestoreDataConverter,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  Timestamp,
} from "firebase/firestore"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { generateId } from "../utils/generateId"
import { useStateWithInitial } from "../utils/useStateWithInitial"
import { useUser } from "./auth"

export type Note = {
  id: string
  title: string
  body: string
  isNew: boolean
  inTrash: boolean
  categoryIds: string[]
  summary: string
  createdAt?: Date
  updatedAt?: Date
}

export type NoteUpdater = (
  update: Partial<Pick<Note, "title" | "body" | "inTrash" | "categoryIds">>,
  isNew?: boolean
) => Promise<void>

const converter: FirestoreDataConverter<Note> = {
  toFirestore: (note) => {
    const { id: _id, createdAt, updatedAt, ...rest } = note
    const data: DocumentData = rest
    if (createdAt !== undefined) {
      data.createdAt =
        createdAt instanceof Date ? Timestamp.fromDate(createdAt) : createdAt
    }
    if (updatedAt !== undefined) {
      data.updatedAt =
        updatedAt instanceof Date ? Timestamp.fromDate(updatedAt) : updatedAt
    }
    return data
  },
  fromFirestore: (snapshot, options): Note => {
    const { title, body, inTrash, createdAt, updatedAt, categoryIds, summary } =
      snapshot.data(options)

    return {
      id: snapshot.id,
      title: String(title ?? ""),
      body: String(body ?? ""),
      isNew: false,
      inTrash: Boolean(inTrash),
      categoryIds: Array.isArray(categoryIds)
        ? categoryIds.map((id) => String(id))
        : [],
      summary: String(summary ?? ""),
      createdAt:
        createdAt instanceof Timestamp ? createdAt.toDate() : new Date(),
      updatedAt:
        updatedAt instanceof Timestamp ? updatedAt.toDate() : new Date(),
    }
  },
}

export function useNote(id: string): [Note | undefined, NoteUpdater] {
  const user = useUser()
  const [note, setNote] = useStateWithInitial<Note | undefined>(undefined, [id])

  const lastSaveRef = useRef<
    { noteId: string; revisionId: string; timestamp: number } | undefined
  >(undefined)

  useEffect(() => {
    if (user === undefined) return

    return onSnapshot(
      doc(getFirestore(), "users", user.uid, "notes", id).withConverter(
        converter
      ),
      (snapshot) => {
        if (snapshot.metadata.hasPendingWrites) return

        setNote(
          snapshot.exists()
            ? snapshot.data()
            : {
                id,
                isNew: true,
                title: "",
                body: "",
                inTrash: false,
                categoryIds: [],
                summary: "",
              }
        )
      }
    )
  }, [user, id, setNote])

  const updateNote: NoteUpdater = useCallback(
    async (update, isNew = false) => {
      if (user === undefined) return

      const actualUpdate: Record<string, unknown> = isNew
        ? {
            title: update.title ?? "",
            body: update.body ?? "",
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
          }
        : {
            ...update,
            updatedAt: serverTimestamp(),
          }

      if (typeof actualUpdate.body === "string") {
        actualUpdate.summary = actualUpdate.body.slice(0, 200)

        const now = Date.now()
        let lastSave = lastSaveRef.current
        if (
          lastSave &&
          (lastSave.noteId !== id || now - lastSave.timestamp > 60_000)
        ) {
          lastSave = undefined
        }

        const revisionId = lastSave?.revisionId ?? generateId()

        await setDoc(
          doc(
            getFirestore(),
            `users/${user.uid}/notes/${id}/revisions/${revisionId}`
          ),
          {
            body: actualUpdate.body,
            createdAt: serverTimestamp(),
          }
        )

        lastSaveRef.current = {
          noteId: id,
          revisionId,
          timestamp: lastSave?.timestamp ?? now,
        }
      }

      await setDoc(
        doc(getFirestore(), "users", user.uid, "notes", id).withConverter(
          converter
        ),
        actualUpdate,
        { merge: true }
      )
    },
    [user, id]
  )

  return [note, updateNote]
}

type NoteSelector =
  | { type: "all" | "in-trash" }
  | { type: "category"; categoryId: string }

export function useNotes(selector: NoteSelector): Note[] | undefined {
  const user = useUser()
  const [notes, setNotes] = useState<Note[] | undefined>(undefined)

  useEffect(() => {
    if (user === undefined) return

    onSnapshot(
      query(
        collection(getFirestore(), "users", user.uid, "notes").withConverter(
          converter
        ),
        orderBy("updatedAt", "desc")
      ),
      (snapshot) => {
        setNotes(snapshot.docs.map((doc) => doc.data()))
      }
    )
  }, [user])

  const filteredNotes = useMemo(() => {
    if (notes === undefined) return undefined

    switch (selector.type) {
      case "all":
        return notes.filter((n) => !n.inTrash)

      case "in-trash":
        return notes.filter((n) => n.inTrash)

      case "category":
        return notes.filter((n) => n.categoryIds.includes(selector.categoryId))
    }
  }, [notes, selector])

  return filteredNotes
}
