import React, { FC } from "react"
import { Textarea } from "./Textarea"

type Props = {
  noteId: string | undefined
  text: string
  onSave: (newText: string) => void
}

export const PlainEditor: FC<Props> = ({ text, onSave }) => {
  return (
    <Textarea
      value={text}
      onChange={onSave}
      display="block"
      h="100%"
      resize="none"
      outline="none"
      px={4}
      py={2}
      pb={16}
      _dark={{ bg: "transparent" }}
    />
  )
}
