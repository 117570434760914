import { Box, Button, Stack } from "@chakra-ui/react"
import React, { FC } from "react"
import { Link } from "react-router-dom"
import { SidebarOpener } from "./SidebarOpener"

export const Home: FC = () => {
  return (
    <Stack h="100%" w="100%" overflow="hidden" spacing={0}>
      <Stack direction="row" px={2} py={2}>
        <SidebarOpener />
      </Stack>
      <Box flex="1" display="flex" alignItems="center" justifyContent="center">
        <Button as={Link} to="/editor">
          New
        </Button>
      </Box>
    </Stack>
  )
}
