import { EditIcon, SettingsIcon } from "@chakra-ui/icons"
import {
  Box,
  Button,
  chakra,
  Editable,
  EditableInput,
  EditablePreview,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Tag,
  useDisclosure,
  Wrap,
  WrapItem,
} from "@chakra-ui/react"
import React, { FC, useCallback, useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { useCategories } from "../logic/categories"
import { useNote } from "../logic/notes"
import { useStateWithInitial } from "../utils/useStateWithInitial"
import { useThrottle } from "../utils/useThrottle"
import { CategorySelectModal } from "./CategorySelectModal"
import { CellEditor } from "./CellEditor"
import { PlainEditor } from "./PlainEditor"
import { SidebarOpener } from "./SidebarOpener"

export const Editor: FC = () => {
  const { noteId } = useParams<"noteId">()

  const [titleDraft, setTitleDraft] = useStateWithInitial("", [noteId])
  const [textDraft, setTextDraft] = useStateWithInitial("", [noteId])
  const [editorMode, setEditorMode] = useState<"cell" | "plain">("cell")

  const internalStates = useRef({
    pendingBodyUpdates: [] as string[],
    pendingTitleUpdates: [] as string[],
  }).current

  const [status, setStatus] = useStateWithInitial<
    "new" | "idle" | "unsaved" | "saving" | "saved" | undefined
  >(undefined, [noteId])

  const throttleTitle = useThrottle(700)
  const throttleBody = useThrottle(700)

  const [note, updateNote] = useNote(noteId as string)

  useEffect(() => {
    if (note === undefined) return

    setStatus((s) => s ?? (note.isNew ? "new" : "idle"))

    const it = internalStates.pendingTitleUpdates.lastIndexOf(note.title)
    if (it === -1) {
      setTitleDraft(note.title)
    } else {
      internalStates.pendingTitleUpdates.splice(0, it + 1)
    }

    const ib = internalStates.pendingBodyUpdates.lastIndexOf(note.body)
    if (ib === -1) {
      setTextDraft(note.body)
    } else {
      internalStates.pendingBodyUpdates.splice(ib, ib + 1)
    }
  }, [note, setStatus, setTextDraft, setTitleDraft, internalStates])

  const onSaveText = useCallback(
    async (newText: string) => {
      setTextDraft(newText)
      internalStates.pendingBodyUpdates.push(newText)

      if (status === "new") {
        throttleBody(async () => {
          setStatus("saving")
          await updateNote({ body: newText }, true)
          setStatus("saved")
        })
      } else {
        setStatus("unsaved")

        throttleBody(async () => {
          setStatus("saving")
          await updateNote({ body: newText }, false)
          setStatus("saved")
        })
      }
    },
    [setTextDraft, status, setStatus, updateNote, throttleBody, internalStates]
  )

  const onChangeTitle = useCallback(
    async (newTitle: string) => {
      setTitleDraft(newTitle)
      internalStates.pendingTitleUpdates.push(newTitle)

      if (status === "new") {
        throttleTitle(async () => {
          setStatus("saving")
          await updateNote({ title: newTitle }, true)
          setStatus("saved")
        })
      } else {
        setStatus("unsaved")

        throttleTitle(async () => {
          setStatus("saving")
          await updateNote({ title: newTitle }, false)
          setStatus("saved")
        })
      }
    },
    [
      setStatus,
      setTitleDraft,
      status,
      throttleTitle,
      updateNote,
      internalStates,
    ]
  )

  const confirmDelete = useCallback(() => {
    updateNote({ inTrash: true }, false)
  }, [updateNote])

  const restoreFromTrash = useCallback(() => {
    updateNote({ inTrash: false }, false)
  }, [updateNote])

  return (
    <Stack h="100%" w="100%" overflow="hidden" spacing={0}>
      <Stack
        direction="row"
        px={2}
        py={2}
        borderBottom="1px solid"
        borderColor="gray.200"
        _dark={{ borderColor: "gray.600" }}
      >
        <SidebarOpener />
        <Editable
          ml={2}
          flex="1"
          value={titleDraft}
          onChange={onChangeTitle}
          placeholder="Untitled"
        >
          <EditablePreview />
          <EditableInput />
        </Editable>

        {note?.inTrash && (
          <chakra.span rounded="sm" color="gray.100" bg="red.400" p={1}>
            IN TRASH
          </chakra.span>
        )}

        <chakra.span
          rounded="sm"
          color="gray.100"
          bg="gray.400"
          p={1}
          _dark={{ color: "gray.700" }}
        >
          {status}
        </chakra.span>

        <Menu>
          <MenuButton
            as={IconButton}
            icon={<SettingsIcon />}
            variant="outline"
            size="sm"
          />
          <MenuList>
            {editorMode === "cell" ? (
              <MenuItem onClick={() => setEditorMode("plain")}>
                Switch to plain editor mode
              </MenuItem>
            ) : editorMode === "plain" ? (
              <MenuItem onClick={() => setEditorMode("cell")}>
                Switch to cell editor mode
              </MenuItem>
            ) : null}
            {note && !note.isNew && !note.inTrash && (
              <MenuItem onClick={confirmDelete}>Move to trash</MenuItem>
            )}
            {note && !note.isNew && note.inTrash && (
              <MenuItem onClick={restoreFromTrash}>Restore from trash</MenuItem>
            )}
          </MenuList>
        </Menu>
      </Stack>

      {note !== undefined && !note.isNew && (
        <Categories
          categoryIds={note.categoryIds}
          onSaveCategoryIds={(categoryIds) => {
            updateNote({ categoryIds }, false)
          }}
        />
      )}

      {note !== undefined &&
        status !== undefined &&
        (editorMode === "cell" ? (
          <CellEditor noteId={noteId} text={textDraft} onSave={onSaveText} />
        ) : editorMode === "plain" ? (
          <PlainEditor noteId={noteId} text={textDraft} onSave={onSaveText} />
        ) : null)}
    </Stack>
  )
}

const Categories: FC<{
  categoryIds: string[]
  onSaveCategoryIds: (categoryIds: string[]) => void
}> = ({ categoryIds, onSaveCategoryIds }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [categories] = useCategories()

  return (
    <>
      <Box
        px={4}
        py={2}
        borderBottomWidth="1px"
        borderColor="gray.200"
        _dark={{ borderColor: "gray.600" }}
      >
        <Wrap>
          <WrapItem color="gray.500">Category:</WrapItem>
          {categories
            .filter((cat) => categoryIds.includes(cat.id))
            .map((cat) => (
              <WrapItem key={cat.id}>
                <Tag>{cat.name}</Tag>
              </WrapItem>
            ))}
          <WrapItem>
            <Button size="xs" onClick={onOpen}>
              <EditIcon />
            </Button>
          </WrapItem>
        </Wrap>
      </Box>
      <CategorySelectModal
        isOpen={isOpen}
        onClose={onClose}
        categoryIds={categoryIds}
        onSelect={(categoryIds) => {
          onSaveCategoryIds([...new Set(categoryIds)])
          onClose()
        }}
      />
    </>
  )
}
