import { chakra, ChakraProps } from "@chakra-ui/react"
import React, {
  ChangeEvent,
  FC,
  forwardRef,
  KeyboardEvent,
  Ref,
  useCallback,
  useLayoutEffect,
  useRef,
} from "react"
import TextareaAutosize, {
  TextareaAutosizeProps,
} from "react-textarea-autosize"

type Props = ChakraProps &
  Omit<TextareaAutosizeProps, "value" | "onChange"> & {
    _ref?: Ref<HTMLTextAreaElement>
    autosize?: boolean
    onChange: (value: string) => void
    value: string
    onSubmit?: () => void
  }

const Textarea: FC<Props> = ({
  _ref,
  autosize,
  value,
  onChange: onChangeOriginal,
  onSubmit,
  ...rest
}) => {
  const callAfterNextRender = useRef<Array<() => void>>([])

  useLayoutEffect(() => {
    if (callAfterNextRender.current.length > 0) {
      for (const f of callAfterNextRender.current) {
        f()
      }
      callAfterNextRender.current = []
    }
  })

  const onChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      onChangeOriginal(e.target.value)
    },
    [onChangeOriginal]
  )

  const onKeyDown = useCallback(
    (e: KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.nativeEvent.isComposing) return

      switch (e.key) {
        case "Enter": {
          if (onSubmit !== undefined && (e.metaKey || e.ctrlKey)) {
            onSubmit()
            break
          }

          const { selectionStart, selectionEnd } = e.target
          if (selectionStart !== selectionEnd) break

          const lineBeginning =
            selectionStart === 0
              ? 0
              : value.lastIndexOf("\n", selectionStart - 1) + 1
          let lineBeginningWithoutSpace = lineBeginning
          let nSpaces = 0
          while (
            lineBeginningWithoutSpace <= selectionStart &&
            value[lineBeginningWithoutSpace] === " "
          ) {
            lineBeginningWithoutSpace++
            nSpaces++
          }

          if (lineBeginningWithoutSpace >= selectionStart) break

          const headMatch = value
            .slice(lineBeginningWithoutSpace)
            .match(/^(?:[-*]|\d+[.)]) /)
          if (headMatch === null) break
          const head = headMatch[0]

          let markToInsert = /^\d/.test(head)
            ? `${Number(head.slice(0, -2)) + 1}${head.slice(-2)}`
            : head
          const maybeCheckbox = value.slice(
            lineBeginningWithoutSpace + 2,
            lineBeginningWithoutSpace + 5
          )
          let beginningWithoutMark = lineBeginningWithoutSpace + head.length
          if (maybeCheckbox === "[ ]" || maybeCheckbox === "[x]") {
            markToInsert += "[ ] "
            beginningWithoutMark += 3
          }

          const shouldDeleteMark =
            beginningWithoutMark === selectionStart ||
            value.slice(beginningWithoutMark, selectionStart).match(/^\s+$/) !==
              null

          e.preventDefault()

          let newPosition: number

          if (shouldDeleteMark) {
            const beforeLine = value.slice(0, lineBeginning)
            const afterCaret = value.slice(selectionStart)
            onChangeOriginal(`${beforeLine}\n${afterCaret}`)
            newPosition = lineBeginning + 1
          } else {
            const beforeCaret = value.slice(0, selectionStart)
            const afterCaret = value.slice(selectionStart)
            const indent = " ".repeat(nSpaces)
            onChangeOriginal(
              `${beforeCaret}\n${indent}${markToInsert}${afterCaret}`
            )
            newPosition = selectionStart + 1 + nSpaces + markToInsert.length
          }

          const { target } = e
          callAfterNextRender.current.push(() => {
            target.setSelectionRange(newPosition, newPosition)
          })
          break
        }

        case "Tab": {
          e.preventDefault()
          const { selectionStart, selectionEnd } = e.target
          const beforeCaret = value.slice(0, selectionStart)
          const afterCaret = value.slice(selectionEnd)

          if (selectionStart === selectionEnd) {
            for (const { pattern, content } of snippets) {
              const m = beforeCaret.match(pattern)
              if (m) {
                const newBeforeCaret = beforeCaret.slice(
                  0,
                  beforeCaret.length - m[0].length
                )
                const contentValue = content()
                const newValue = `${newBeforeCaret}${contentValue}${afterCaret}`

                const newPosition = newBeforeCaret.length + contentValue.length
                onChangeOriginal(newValue)

                const { target } = e
                callAfterNextRender.current.push(() => {
                  target.setSelectionRange(newPosition, newPosition)
                })
                return
              }
            }
          }

          const newValue = `${beforeCaret}    ${afterCaret}`
          const newPosition = selectionStart + 4
          onChangeOriginal(newValue)

          const { target } = e
          callAfterNextRender.current.push(() => {
            target.setSelectionRange(newPosition, newPosition)
          })

          break
        }
      }
    },
    [onSubmit, value, onChangeOriginal]
  )

  return (
    <chakra.textarea
      ref={_ref}
      as={autosize ? TextareaAutosize : "textarea"}
      {...rest}
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
    />
  )
}

const WrappedTextarea = forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
  return <Textarea {...props} _ref={ref} />
})
WrappedTextarea.displayName = "Textarea"
export { WrappedTextarea as Textarea }

const snippets = [
  {
    pattern: /\b(?:today)$/,
    content: () => {
      const date = new Date()
      const y = date.getFullYear().toString()
      const m = (date.getMonth() + 1).toString().padStart(2, "0")
      const d = date.getDate().toString().padStart(2, "0")
      return `${y}-${m}-${d}`
    },
  },
  {
    pattern: /\b(?:now)$/,
    content: () => {
      const date = new Date()
      const y = date.getFullYear().toString()
      const m = (date.getMonth() + 1).toString().padStart(2, "0")
      const d = date.getDate().toString().padStart(2, "0")
      const h = date.getHours().toString().padStart(2, "0")
      const mi = date.getMinutes().toString().padStart(2, "0")
      return `${y}-${m}-${d} ${h}:${mi}`
    },
  },
]
