import { Dispatch, SetStateAction, useRef, useState } from "react"

export function useStateWithInitial<T>(
  initial: T | (() => T),
  deps: Array<unknown>
): [T, Dispatch<SetStateAction<T>>] {
  const [value, setValue] = useState<T>(initial)
  const prevDeps = useRef<Array<unknown>>([])

  if (
    deps.length !== prevDeps.current.length ||
    deps.some((v, i) => v !== prevDeps.current[i])
  ) {
    prevDeps.current = deps
    const initialValue =
      typeof initial === "function" ? (initial as () => T)() : initial
    setValue(initialValue)
    return [initialValue, setValue]
  }

  return [value, setValue]
}
