import {
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Spinner,
  useBreakpointValue,
} from "@chakra-ui/react"
import React, { FC, useMemo, useRef } from "react"
import { Navigate, Outlet } from "react-router-dom"
import { useUserWithLoadingState } from "../logic/auth"
import { Sidebar, SidebarInDrawer } from "./Sidebar"
import { useSidebarHandler } from "./SidebarOpener"

export const BaseLayout: FC = () => {
  const [user, isLoadingUser] = useUserWithLoadingState()
  const isSignedIn = useMemo(() => user !== undefined, [user])

  const showSidebar = useBreakpointValue({ base: false, md: true })
  const buttonRef = useRef<HTMLButtonElement | null>(null)
  const { isOpen, onClose } = useSidebarHandler()

  if (isLoadingUser) return <Loading />

  if (!isSignedIn) {
    return <Navigate to="/about" />
  }

  return (
    <Box display="flex" h="100%" flexDir="column">
      <Box>
        {!showSidebar && (
          <Drawer
            isOpen={isOpen}
            placement="left"
            onClose={onClose}
            finalFocusRef={buttonRef}
          >
            <DrawerOverlay />
            <DrawerContent>
              <SidebarInDrawer />
            </DrawerContent>
          </Drawer>
        )}
      </Box>
      <Box display="flex" h="100%" flex={1} flexDir="row">
        {showSidebar && (
          <Box
            w="240px"
            boxShadow="0 0 12px #ccc9"
            zIndex={1}
            _dark={{ boxShadow: "none" }}
          >
            <Sidebar />
          </Box>
        )}
        <Box flex={1}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  )
}

const Loading: FC = () => (
  <Box
    width="100%"
    height="100%"
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    <Spinner />
  </Box>
)
