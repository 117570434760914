import { FC, useEffect, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { generateId } from "../utils/generateId"

export const NewEditor: FC = () => {
  const newId = useMemo(() => generateId(), [])
  const navigate = useNavigate()

  useEffect(() => {
    navigate(`/editor/${newId}`)
  }, [navigate, newId])

  return null
}
