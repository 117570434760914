import { ChakraProvider, extendTheme, ThemeConfig } from "@chakra-ui/react"
import React, { FC } from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { About } from "./About"
import { BaseLayout } from "./BaseLayout"
import { Editor } from "./Editor"
import { Home } from "./Home"
import { NewEditor } from "./NewEditor"
import { SidebarOpenerProvider } from "./SidebarOpener"

const themeConfig: ThemeConfig = {
  initialColorMode: "system",
  useSystemColorMode: true,
}
const theme = extendTheme({ config: themeConfig })

export const App: FC = () => (
  <BrowserRouter>
    <ChakraProvider theme={theme}>
      <SidebarOpenerProvider>
        <Routing />
      </SidebarOpenerProvider>
    </ChakraProvider>
  </BrowserRouter>
)

const Routing: FC = () => (
  <Routes>
    <Route path="/" element={<BaseLayout />}>
      <Route index element={<Home />} />
      <Route path="editor/" element={<NewEditor />} />
      <Route path="editor/:noteId" element={<Editor />} />
    </Route>
    <Route path="about" element={<About />} />
  </Routes>
)
