import { useCallback, useEffect, useRef } from "react"

type Timer = {
  timer: number
  func: () => void
}

export function useThrottle(ms: number): (f: () => void) => void {
  const timerRef = useRef<Timer>()

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        const { timer, func } = timerRef.current
        func()
        clearTimeout(timer)
        timerRef.current = undefined
      }
    }
  }, [])

  const throttle = useCallback(
    (func: () => void) => {
      if (timerRef.current) {
        clearTimeout(timerRef.current.timer)
      }

      const timer = setTimeout(() => {
        func()
        timerRef.current = undefined
      }, ms)
      timerRef.current = { timer, func }
    },
    [ms]
  )

  return throttle
}
