import { HamburgerIcon } from "@chakra-ui/icons"
import { Button, useBreakpointValue, useDisclosure } from "@chakra-ui/react"
import React, { createContext, FC, PropsWithChildren, useContext } from "react"

export const SidebarOpener: FC = () => {
  const showSidebar = useBreakpointValue({ base: false, md: true })
  const { onOpen } = useSidebarHandler()

  if (showSidebar) return null

  return (
    <Button size="sm" onClick={onOpen}>
      <HamburgerIcon />
    </Button>
  )
}

type SidebarHandler = {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

const Context = createContext<SidebarHandler>({
  isOpen: false,
  onOpen: () => {
    /* empty */
  },
  onClose: () => {
    /* empty */
  },
})

export const SidebarOpenerProvider: FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const handler: SidebarHandler = useDisclosure()

  return <Context.Provider value={handler}>{children}</Context.Provider>
}

export function useSidebarHandler(): SidebarHandler {
  return useContext(Context)
}
