import {
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithPopup,
  User,
} from "firebase/auth"
import { useEffect, useState } from "react"

export function useUserWithLoadingState(): [User | undefined, boolean] {
  const [user, setUser] = useState(() => getAuth().currentUser ?? undefined)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    return onAuthStateChanged(getAuth(), (user) => {
      setUser(user ?? undefined)
      setIsLoading(false)
    })
  }, [])

  return [user, isLoading]
}

export function useUser(): User | undefined {
  const [user] = useUserWithLoadingState()
  return user
}

export function signIn(): void {
  signInWithPopup(getAuth(), new GoogleAuthProvider())
}
