import { ChevronDownIcon } from "@chakra-ui/icons"
import {
  Box,
  Button,
  chakra,
  DrawerCloseButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Tag,
  useModalContext,
  Wrap,
  WrapItem,
} from "@chakra-ui/react"
import { css } from "@emotion/react"
import React, { FC, useMemo, useState } from "react"
import { Link as ReactRouterLink, useParams } from "react-router-dom"
import { Category, useCategories } from "../logic/categories"
import { Note, useNotes } from "../logic/notes"
import { formatDate } from "../utils/formatDate"

type CategoryLikeItem =
  | {
      type: "all" | "in-trash"
      name: string
    }
  | {
      type: "category"
      categoryId: string
      name: string
    }
const categoryLikeItems: CategoryLikeItem[] = [
  { type: "all", name: "All" },
  { type: "in-trash", name: "Trash" },
]

export const Sidebar: FC<{
  inDrawer?: boolean
  onCloseDrawer?: () => void
}> = ({ inDrawer, onCloseDrawer }) => {
  const [selectedItem, setSelectedItem] = useState(categoryLikeItems[0])

  const notes = useNotes(selectedItem)
  const [categories] = useCategories()

  return (
    <Box h="100%" position="relative" _dark={{ bg: "gray.700" }}>
      <Box h="100%" overflowY="scroll">
        <Stack
          direction="column"
          boxShadow="0 0 4px #888a"
          spacing="0"
          _dark={{
            boxShadow: "none",
            borderBottom: "1px solid",
            borderColor: "gray.600",
          }}
        >
          <chakra.header
            fontSize="xl"
            fontWeight="bold"
            position="sticky"
            top="0"
            bg="white"
            px="2"
            py="1"
            pb="0"
            _dark={{ bg: "transparent" }}
          >
            Wikiwi
            {inDrawer && <DrawerCloseButton right="1" top="1" />}
          </chakra.header>
          <Box p={1}>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                w="100%"
                size="xs"
              >
                {selectedItem.name}
              </MenuButton>
              <MenuList>
                {categoryLikeItems.map((c) => (
                  <MenuItem key={c.type} onClick={() => setSelectedItem(c)}>
                    {c.name}
                  </MenuItem>
                ))}
                {categories.map((c) => (
                  <MenuItem
                    key={c.id}
                    onClick={() =>
                      setSelectedItem({
                        type: "category",
                        categoryId: c.id,
                        name: c.name,
                      })
                    }
                  >
                    {c.name}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </Box>
        </Stack>
        {notes && <NoteList notes={notes} onCloseDrawer={onCloseDrawer} />}
      </Box>
      <Button
        as={ReactRouterLink}
        to="/editor"
        colorScheme="orange"
        position="absolute"
        bottom="3"
        right="3"
        boxShadow="md"
        onClick={() => onCloseDrawer?.()}
      >
        New
      </Button>
    </Box>
  )
}

export const SidebarInDrawer: FC = () => {
  const { onClose } = useModalContext()
  return <Sidebar inDrawer onCloseDrawer={onClose} />
}

const NoteList: FC<{ notes: Note[]; onCloseDrawer?: () => void }> = ({
  notes,
  onCloseDrawer,
}) => {
  const { noteId } = useParams<"noteId">()
  const [categories] = useCategories()

  return (
    <chakra.ul pb={10}>
      {notes.map((note, i) => (
        <chakra.li
          key={note.id}
          borderTop={i === 0 ? "" : "1px solid"}
          borderColor="gray.100"
          cursor="pointer"
          _hover={{ bg: "gray.100" }}
          _dark={{
            borderColor: "gray.600",
            bg: noteId === note.id ? "blue.900" : undefined,
            _hover: { bg: "gray.800" },
          }}
          bg={noteId === note.id ? "blue.100" : undefined}
        >
          {
            <Link
              as={ReactRouterLink}
              to={`/editor/${note.id}`}
              onClick={onCloseDrawer}
              display="block"
              px="2"
              py="1"
              textDecoration="none"
              _hover={{ textDecoration: "none" }}
            >
              <chakra.div
                fontWeight="bold"
                color="gray.700"
                _dark={{ color: "gray.200" }}
              >
                {note.title || "Untitled"}
              </chakra.div>
              <Categories
                categories={categories}
                categoryIds={note.categoryIds}
              />
              <chakra.div
                fontSize="sm"
                color="gray.500"
                display="-webkit-box"
                css={css`
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                  overflow: hidden;
                `}
              >
                {note.body.slice(0, 100)}
              </chakra.div>
              <chakra.div
                fontSize="sm"
                color="gray.700"
                _dark={{ color: "gray.400" }}
              >
                {note.updatedAt && formatDate(note.updatedAt)}
              </chakra.div>
            </Link>
          }
        </chakra.li>
      ))}
    </chakra.ul>
  )
}

const Categories: FC<{ categories: Category[]; categoryIds: string[] }> = ({
  categories,
  categoryIds,
}) => {
  const filteredCategories = useMemo(
    () => categories.filter((cat) => categoryIds.includes(cat.id)),
    [categories, categoryIds]
  )
  if (filteredCategories.length === 0) return null

  return (
    <Wrap my={1}>
      {filteredCategories.map((cat) => (
        <WrapItem key={cat.id}>
          <Tag colorScheme="gray" variant="outline">
            {cat.name}
          </Tag>
        </WrapItem>
      ))}
    </Wrap>
  )
}
